<template>
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-body">
                <div class="bg-image">
                    <div class="row justify-content-center">
                        <div class="col-12 ">
                            <h2 class="h2-heading">Income Tax Return Filing</h2>
                            <p class="p-text">Vakilgiri Legals has made Online Tax Filing super easy! Take the first
                                step towards filing your income tax return. Select all the sources of your income (to
                                accurately e-file) and click on Continue to experience Vakilgiri's customized &amp; the
                                easiest e-filing process.</p>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6 p-2">
                            <div class="card">
                                <div class="card-header"></div>
                                <div class="card-body">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <Field name="field" rules="alpha" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'Test123',
    components: {
        // ValidationProvider: ValidationProvider,
    },
    data() {
        return {
            //   termCondition: 'false1'  
        }
    },
    methods: {
    },
    created() {
    },
}
</script>

<style scoped>

</style>